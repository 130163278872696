<template>


  <div class="family-show" v-if="family">

    <div class="container">
      <div class="row">

        <div class="col s12">

          <div class="family-show__info">

            <div class="row">
              <div class="col s12">

                <router-link to="/" title="Radio Kraków Kultura" class="breadcrumb">
                  Radio Kraków Kultura
                </router-link>

                <router-link to="/audycje" title="Audycje" class="breadcrumb">
                  Audycje
                </router-link>
                <router-link :to="'/'+family.url" :title="family.title" class="breadcrumb">
                  {{ family.title }}
                </router-link>
              </div>
            </div>


            <div class="row">
              <div class="col s12 l3">
                <div class="family-show__thumb"
                     :style="{ backgroundImage: `url(${family.thumb})` }">

                </div>
              </div>
              <div class="col s12 l9">

                <h1 class="big-title">
                  {{ family.title }}
                </h1>


                <div class="family-show__roadmap" v-if="family.status == 'live'">
                  {{ family.roadmap_str }}
                </div>

                <div class="family-show__archive" style="display: none;" v-if="family.status == 'archive'">
                  Audycja archiwalna
                </div>


                <p class="family-show__text mb-4" v-html="family.text" :class="{
                  'family-show__text-full': show
                }"></p>

                <div class="family-show__show-more" v-if="!show && family.text && family.text.length > 200" @click="show = !show">Pokaż więcej</div>

                <UserSmallList :users="family.users"/>
              </div>
            </div>


          </div>

        </div>
      </div>
    </div>

  </div>

  <div class="container">
    <div class="row">
      <div class="col s12">


        <h1 class="big-title mb-3">
          Podcasty
        </h1>


        <div v-if="!articles || articles.length ==0 ">


          <div class="clearfix"></div>
          <div class="card color darken-1">
            <div class="card-content white-text">
              <p>Brak</p>
            </div>
          </div>

        </div>

        <div v-if="articles">
          <PodcastLine :article="article" v-for="article in articles"/>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import axios from "@/axios2";
import PodcastLine from "@/views/Audio/Part/PodcastLine";
import CoreArray from "@/helpers/array.js";
import UserSmallList from "../People/Part/SmallList";

export default {
  name: "ArticleFamilyShow",
  components: {UserSmallList, PodcastLine},
  props: ['slug'],
  data() {
    return {
      'show': false,
      'family': null,
      'articles': null,
    }
  },
  methods: {
    refresh: function () {


      axios
          .get('articlefamily/get', {
            params: {
              slug: this.slug,
              scope: 'title,thumb,roadmap_str,slug,date,url,text,status,users,articles',
              articles_scope: 'url,title,block_thumb,text,users,audio,audio_url,audio_time,id,labels,date',
              articles_pars: {'is_show': true},
              users_scope: 'name,firstname,lastname,account_type,avatar_url,url_full',
              is_show: true
            }
          })
          .then(response => {

            let data = response.data.data;

            let from = "https://off.radiokrakow.pl/api/"
            let to = "https://off.radiokrakow.pl/"
            let column = 'thumb';

            // data = CoreArray.changeDomain(data, column, from, to);

            let family = data[0];
            let articles = family.articles;

            // articles = CoreArray.changeDomain(articles, 'thumb', from, to);
            // articles = CoreArray.changeDomain(articles, 'block_thumb', from, to);
            // articles = CoreArray.changeDomain(articles, 'audio_url', from, to);
            articles = CoreArray.changeDomain(articles, 'url', 'audycje', 'podcasty');


            this.family = family;
            this.articles = articles;

            this.$root.metaSet(this.family.title, this.family.text, this.family.thumb)

          })

    }
  },
  created() {

    this.refresh();

  }
}
</script>

<style scoped lang="scss">

.family-show {

  &__roadmap{

    color: #c4c4c4;
  }

  &__thumb {
    width: 100%;
    height: 0;
    padding-bottom: 67%;
    background-size: cover;
  }

  &__text {
    max-height: 77.5px;
    overflow: hidden;
    &-full{
      max-height: unset;
    }
  }

  &__show-more{
    display: block;

    width: 100%;
    text-align: center;
    font-style: italic;
    margin-top: -20px;
    margin-bottom: 20px;

    cursor: pointer;
    color: #1d4bfe;
  }

}

</style>